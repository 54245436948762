<template>
  <div class="main-content">
    <avue-crud
      :data="tableData"
      :option="option"
      :table-loading="showLoading"
      @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button
            size="medium"
            type="primary"
            icon="el-icon-plus"
            @click="handleCreate"
            >添加</el-button
          >
        </div>
      </template>

      <template slot="menu" slot-scope="{ row }">
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          v-if="row.parentId === '0'"
          @click="handleCreateSon(row)"
          >添加子集</el-button
        >
        <span
          style="color: #d9001b; margin-right: 10px"
          v-if="row.parentId != '0' && row.isHot && row.isHot == 1"
          >热门商圈</span
        >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-edit"
          @click="handleEdit(row)"
          >编辑</el-button
        >
        <el-button
          type="text"
          size="mini"
          icon="el-icon-delete"
          @click="handleDelete(row)"
          >删除</el-button
        >
      </template>
    </avue-crud>

    <el-dialog
      :title="`${form.id ? '编辑' : '新增'}`"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="30%"
      top="10vh"
      :before-close="handleClose"
    >
      <avue-form
        ref="form"
        v-model="form"
        :option="formOption"
        @submit="submit"
      >
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
        <template slot="isHot" slot-scope="{ row }">
          <el-switch
            v-model="form.isHot"
            :inactive-value="2"
            :active-value="1"
          ></el-switch>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import { add, list, view, update, deleteNew } from "@/api/build/region";

export default {
  name: "region",
  data() {
    return {
      showState: false,
      tableData: [],
      showLoading: false,
      option: {
        title: "设置区县",
        titleSize: "h3",
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: false, // 显示索引
        size: "small",
        selection: false, // 显示多选框
        page: false, // 显示分页
        align: "center",
        menuAlign: "center",
        menuType: "text",
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "名称",
            prop: "name",
            align: "left",
          },
        ],
      },
      dialogVisible: false,
      form: {
        id: "",
        name: "",
        cityId: "",
        countyLevel: 1,
        parentId: 0,
        isHot: 2,
      },
      formOption: {
        submitText: "确定",
        emptyBtn: false,
        labelWidth: 150,
        column: [
          {
            label: "名称",
            prop: "name",
            maxlength: 10,
            showWordLimit: true,
            append: "字",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入标题",
                trigger: "blur",
              },
            ],
          },
          {
            label: "是否为热门商圈",
            prop: "isHot",
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择",
              },
            ],
            display: false,
          },
        ],
      },
    };
  },
  mounted() {
    // console.log(this.$route.query.id)
    this.form.cityId = this.$route.query.id;
  },
  methods: {
    onLoad() {
      this.showLoading = true;
      list({ id: this.$route.query.id }).then((res) => {
        // console.log(res)
        this.showLoading = false;
        if (res.code === 200) {
          this.tableData = res.data || [];
        }
      });
    },
    // 添加
    handleCreate() {
      // console.log('添加')
      const formState = this.findObject(this.formOption.column, "isHot");
      formState.display = false;
      this.dialogVisible = true;
      this.form.countyLevel = 1;
    },
    // 添加子集
    handleCreateSon(row) {
      const formState = this.findObject(this.formOption.column, "isHot");
      formState.display = true;
      this.dialogVisible = true;
      this.form.parentId = row.id;
      this.form.countyLevel = 2;
    },
    // 编辑
    async handleEdit(row) {
      if (row.parentId != 0) {
        const formState = this.findObject(this.formOption.column, "isHot");
        formState.display = true;
      } else {
        const formState = this.findObject(this.formOption.column, "isHot");
        formState.display = false;
      }

      const { data } = await view({ id: row.id });
      // console.log(data)
      this.form.id = data.id;
      this.form.name = data.name;
      this.form.isHot = data.isHot;
      this.dialogVisible = true;
    },
    // 删除
    handleDelete(row) {
      this.$confirm("是否删除此区县数据?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteNew({
          id: row.id,
        }).then((res) => {
          if (res.code === 200) {
            this.$message.success("删除成功");
            this.onLoad();
          } else {
            this.$message.error(res.msg);
          }
        });
      });
    },
    // form提交
    submit(form, done) {
      // console.log(form)
      if (form.id) {
        update(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("添加成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.warning(res.msg);
              done();
            }
          })
          .catch(() => done());
      } else {
        add(form)
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("添加成功");
              this.dialogVisible = false;
              setTimeout(() => {
                this.resetForm();
              }, 500);
              this.onLoad();
            } else {
              this.$message.warning(res.msg);
              done();
            }
          })
          .catch(() => done());
      }
    },
    // 关闭弹框
    handleClose() {
      this.dialogVisible = false;
      setTimeout(() => {
        this.resetForm();
      }, 500);
    },
    resetForm() {
      this.form = {
        id: "",
        name: "",
        cityId: this.$route.query.id,
        countyLevel: 1,
        parentId: 0,
      };
    },
  },
};
</script>

<style scoped>
/deep/ .el-form-item__label {
  width: 135px;
}
</style>