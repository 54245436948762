import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/build/city/county/list/' + params.id, '');

export const add = params => post('/web/build/city/county/add', params);

export const view = params => get('/web/build/city/county/view/' + params.id, '');

export const update = params => put('/web/build/city/county/update', params);

export const deleteNew = params => Delete('/web/build/city/county/delete/' + params.id, '');